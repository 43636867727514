$(document).ready(function() {
    $('.owl-carousel').owlCarousel({
        autoPlay: true,
        singleItem: true
    });
    $('button.rush, a.rush').click(function() {
        $('html, body').animate({
            scrollTop: $('#form h1').offset().top
        }, 'slow');
    });
});

// Footer Pop Out
function openPopUpUrl(e,t,a){return window.open(e,"","height="+t+", width="+a+", scrollbars=yes, resizable=yes");}

$(document).ready(function() {
    $(".move-to-form").click(function(a) {
        a.preventDefault(), $("html,body").animate({
            scrollTop: $(".form-section").offset().top
        }, 1100)
    }), $("#province").focus(function() {
        $(".form-section .form-control#province").css("color", "#555")
    })
})

setTimeout(function() {
    $(".popup").show().animate({
        top: "-=3.7em"
    }, 1e3), setTimeout(function() {
        $(".popup").animate({
            top: "+=3.7em"
        }, 1e3)
    }, 9e3)
}, 5e3)

// CountDown
var timeoutHandle;
function countdown(minutes,seconds) {
        var minutes = minutes;
        var seconds = seconds;

    function tick() {
        var counter = document.getElementById("counter");
        counter.innerHTML = minutes.toString() + ":" + (seconds < 10 ? "0" : "") + String(seconds);

        if (seconds > 0) {
                seconds--;
                timeoutHandle = setTimeout(tick, 1000);
        } else {
            if (minutes > 1) {

                setTimeout(function () {
                    countdown(minutes - 1, 59);
                }, 1000);

            } else {
                
                if (minutes > 0) {
                        setTimeout(function () {
                                countdown(0,59);
                        }, 1000);   
                }
            }
        }
    }
    tick();
}
countdown(9,47);

var handleClaimed = {
        currentClaimed: 84,

        // Progress bar init
        init: function(){

                $('.progress-line__inner').animate({
                        width: this.currentClaimed + "%"
                }, 2000);  

                // Animate initial counter number
                var countTo = handleClaimed.currentClaimed;
                $({ countNum: $('.claimed-counter').text()}).animate(
                {
                        countNum: countTo
                },
                {
                        duration: 2000,
                        easing:'linear',
                        step: function() {
                                $('.claimed-counter').text(Math.floor(this.countNum));
                        },
                        complete: function() {
                                $('.claimed-counter').text(this.countNum);
                        }
                });       

                // Progress bar 5s update
                handleClaimed.currentClaimed++;
                setTimeout(handleClaimed.randomBarUpdate, 6000);
        },

        // Update progress bar and number
        progressBarUpdate: function(){

                // Update progress bar and number
                var newWidth = this.currentClaimed + "%";

                if(this.currentClaimed <= 99){
                        $('.claimed-counter').text(this.currentClaimed);
                        $('.progress-line__inner').animate({
                                width: newWidth
                        }, 500);

                        this.currentClaimed++;
                }
                
        },

        // Randomize progress bar update
        randomBarUpdate: function(){
                handleClaimed.progressBarUpdate();
                setTimeout(handleClaimed.randomBarUpdate, Math.random() * 50000);
        }
};
handleClaimed.init();